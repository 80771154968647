/** 完整权限数据
authority:{btn:'search,view,edit,del,add'}
btn按钮权限：search 搜索按钮，view 查看按钮，edit 编辑按钮，del 删除按钮，add 新增按钮
 */
import i18n from '@/i18n/index'
const t = i18n.global.t


export const magHeadermenu = [{
    path: '/mag/asset/index',
    meta: {
      title: t('mag.router.assetindex'),
      icon: 'Coin'
    },
    children: [{
      path: '/mag/asset/index',

      component: () => import('@/views/mag/view/asset/index.vue'),
      meta: {

        title: t('mag.router.assetindex'),
        pPath: '/mag/asset/index',
        authority: {
          btn: 'add',
          tableBtn: 'view,edit,del'
        }
      },
    }, {
      path: '/mag/asset/view',

      component: () => import('@/views/mag/view/asset/view/index.vue'),
      meta: {
        elmainClass: 'hasGobackMain',
        title: t('mag.router.assetview'),
        pPath: '/mag/asset/index',
        authority: {
          btn: 'add',
          tableBtn: 'edit,del'
        }
      },
    }]
  },
  {
    path: '/mag/device/info',
    meta: {
      title: t('mag.router.deviceinfoTitle'),
      icon: 'Setting',

    },
    children: [{
        path: '/mag/device/info',

        component: () => import('@/views/mag/view/device/info/index.vue'),
        meta: {

          title: t('mag.router.deviceinfo'),
          icon: 'Tickets',
          pPath: '/mag/device/info',
          leftpPath: '/mag/device/info',
          leftMenuHidden: false,
          elmainClass: 'hasTreeBoxMain',
          authority: {
            btn: 'search,add,addMore,update,export,dellMore,addLnglatMore',
            tableBtn: 'edit,del',

          }
        },
      },
      {
        path: '/mag/device/groupMag',
        component: () => import('@/views/mag/view/device/groupMag/index.vue'),
        meta: {
          title: t('mag.router.devicegroupMag'),
          icon: 'Grid',
          leftMenuHidden: false,
          pPath: '/mag/device/info',
          leftpPath: '/mag/device/groupMag',
          elmainClass: 'hasTreeBoxMain',
          authority: {
            btn: 'add',
            tableBtn: 'edit,view,del'
          }
        },
      },
      {
        path: '/mag/device/groupMag/view',
        component: () => import('@/views/mag/view/device/groupMag/view/index.vue'),
        meta: {
          title: t('mag.router.devicegroupMagview'),
          icon: 'denggan',
          leftMenuHidden: false,
          leftMenuItemHidden: true,
          pPath: '/mag/device/info',
          leftpPath: '/mag/device/groupMag',
          elmainClass: 'hasGobackMain',
          authority: {
            btn: 'searchShowForm,searchHideForm,editDevicesGroup',
            tableBtn: 'editDevicesGroup'
          }
        },
      },
      {
        path: '/mag/device/setting',
        component: () => import('@/views/mag/view/device/setting/index.vue'),
        meta: {
          title: t('mag.router.Setting'),
          icon: 'Setting',
          leftMenuHidden: false,

          pPath: '/mag/device/info',
          leftpPath: '/mag/device/setting',
          elmainClass: 'hasTreeBoxMain',
          authority: {
            btn: 'add,add1',
            tableBtn: 'del2,edit'
          }
        },
      },
      {
        path: '/mag/device/energy',
        component: () => import('@/views/mag/view/device/energy/index.vue'),
        meta: {
          title: t('mag.router.energy'),
          icon: 'Odometer',
          leftMenuHidden: false,

          pPath: '/mag/device/info',
          leftpPath: '/mag/device/energy',
          elmainClass: 'hasTreeBoxMain',
          authority: {
            btn: 'searchShowForm,export,add',
          }
        },
      },
	  {
	    path: '/mag/device/activity',
	    component: () => import('@/views/mag/view/device/activity/index.vue'),
	    meta: {
	      title: t('mag.router.activity'),
	      icon: 'Odometer',
	      leftMenuHidden: false,
	      pPath: '/mag/device/info',
	      leftpPath: '/mag/device/activity',
	      elmainClass: 'hasTreeBoxMain',
	      authority: {
	        btn: 'searchShowForm,exportExcel,exportWord,dellMore',
			tableBtn:'del,view'
	      }
	    },
	  },
	  {
	    path: '/mag/device/activity/view',
	    component: () => import('@/views/mag/view/device/activity/view/index.vue'),
	    meta: {
	  	// title: '设备数据记录',
	  	pPath: '/mag/device/activity/index',
	  	authority: {
	  		btn: '',
	  		tableBtn:''
	  	}
	    },
	  },
    ]
  },
  {
    path: '/mag/deviceController/index',
    meta: {
      title: t('mag.router.deviceController'),
      icon: 'Document'
    },
    children: [{
      path: '/mag/deviceController/index',

      component: () => import('@/views/mag/view/deviceController/index.vue'),
      meta: {
		elmainClass: 'hasTreeBoxMain',
        title: t('mag.router.deviceController'),
        pPath: '/mag/deviceController/index',
        authority: {
          btn: 'searchShowForm,search,add,lightOption,rd,syncTime,reb,as,SIM,batchReadIPP,batchIPP,setGP,setLdcs,skhl,setHlgg,setJwdpyz,setGJZQ,setHgqzsb,setKglsc,setSbsjzq,setJzqczkg,syncRgj,syncJwd,syncRhlgg,syncHlNb,setHlNb,setHlRm,setHLZR,getHLZR,getSxjlxs,setSxjlxs,rjl,syncDDZR,setDDZR,setBmd,syncBmd,syncHLZR,syncDDQKGGTG,setLxzq,setJzqckg,tgByGroup,setDDQKRBMS,setDDQKGGTG,setWSGD,reset,setGpnum,setTM,readTm,setLxkg,syncJr,syncZr,syncGpnum,syncDDQKJR,setDDQKJR,setWlid,syncWlid,fotaCenterLight,setTgbc,setTgrbms,setTgnb,setTgzr,setTggg,jzqczkg,syncTgnb,syncTggg,syncTgzr,async8DV,async3SI,async3SO,clearCache,dimLightOption,setTglj,save,setTgmrv',
        }
      },
    }, 
	{
      path: '/mag/deviceController/view',

      component: () => import('@/views/mag/view/deviceController/view/index.vue'),
      meta: {
        title: '设备数据记录',
        pPath: '/mag/deviceController/index',
        authority: {
          btn: 'searchShowForm',
        }
      },
    }]
  },
  {
    path: '/mag/alarm/index',
    meta: {
      title: t('mag.router.alarmindex'),
      icon: 'Bell'
    },
    children: [{
      path: '/mag/alarm/index',

      component: () => import('@/views/mag/view/alarm/index.vue'),
      meta: {
        elmainClass: 'hasTreeBoxMain',
        title: t('mag.router.alarmindex'),
        pPath: '/mag/alarm/index',
        authority: {
          btn: 'searchShowForm,add',
          tableBtn: 'edit'
        }
      },
    }]
  },
  {
    path: '/mag/ruleStrategy/index',
    meta: {
      title: t('mag.router.ruleStrategyindex'),
      icon: 'SetUp'
    },
    children: [{
      path: '/mag/ruleStrategy/index',

      component: () => import('@/views/mag/view/ruleStrategy/index.vue'),
      meta: {

        title: t('mag.router.ruleStrategyindex'),
        pPath: '/mag/ruleStrategy/index',
        authority: {
          btn: 'add1,add2,add',
          tableBtn: 'edit,del'
        }
      },
    }]
  },
  {
    path: '/mag/log/index',
    meta: {
      title: t('mag.router.logindex'),
      icon: 'Document'
    },
    children: [{
      path: '/mag/log/index',

      component: () => import('@/views/mag/view/log/index.vue'),
      meta: {

        title: t('mag.router.logindex'),
        pPath: '/mag/log/index',
        authority: {
          btn: 'searchShowForm,export,clear,del',
        }
      },
    }]
  },
  
  {
    path: '/mag/user/index',
    meta: {
      title: t('mag.router.user'),
      icon: 'User',
      authorityUser: 'admin'
    },
    children: [{
      path: '/mag/user/index',

      component: () => import('@/views/mag/view/user/index.vue'),
      meta: {

        title: t('mag.router.user'),
        pPath: '/mag/user/index',
        authorityUser: 'admin',
        authority: {
          btn: 'search,add,del,pswchange',
          tableBtn: 'edit,batchDel,enabledisable,logout'
        }
      },
    }]
  },
  {
    path: '/mag/deviceSearch/index',
    meta: {
      title: t('mag.router.deviceSearch'),
      icon: 'Search',
      authorityUser: 'admin'
    },
    children: [{
      path: '/mag/deviceSearch/index',

      component: () => import('@/views/mag/view/deviceSearch/index.vue'),
      meta: {

        title: t('mag.router.deviceSearch'),
        pPath: '/mag/deviceSearch/index',
        authorityUser: 'admin',
        authority: {
          btn: 'add,del,pswchange',
          tableBtn: 'edit,batchDel,enabledisable,logout'
        }
      },
    }]
  },
  // {
  //   path: '/mag/communication/index',
  //   meta: {
  //     title: t('mag.router.communication'),
  //     icon: 'Link',
  //     authorityUser: 'admin'
  //   },
  //   children: [{
  //     path: '/mag/communication/index',
  
  //     component: () => import('@/views/mag/view/communication/index.vue'),
  //     meta: {
  
  //       title: t('mag.router.communication'),
  //       pPath: '/mag/communication/index',
  //       authorityUser: 'admin',
  //       authority: {
  //         btn: 'search,pswchange',
  //         tableBtn: 'edit,batchDel,enabledisable,logout'
  //       }
  //     },
  //   }]
  // },
]